<template>
    <div>
        <div class="page-content-search">
            <el-form ref="form" :model="search" size="mini" :label-width="this.env.search_label_width">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="姓名">
                            <el-input v-model="search.user_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="手机号">
                            <el-input v-model="search.phone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-form-item>
                            <el-button icon="el-icon-search" size="mini" type="primary" @click="is_search">搜索
                            </el-button>
                        </el-form-item>
                    </el-col>
                    <!--                    <el-col :span="3">-->
                    <!--                        <el-form-item>-->
                    <!--                            <el-button icon="el-icon-circle-close" size="mini" @click="init">清空搜索</el-button>-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->
                </el-row>
            </el-form>
        </div>

        <el-table
                size="medium"
                border
                v-loading="loading"
                :data="tableData"
                style="width: 100%">
            <el-table-column
                    prop="avatar_url"
                    label="微信头像"
                    width="80">
                <template slot-scope="scope">
                    <el-avatar shape="square" :size="22" :src="scope.row.avatar_url"></el-avatar>
                </template>
            </el-table-column>
            <el-table-column
                    prop="user_nick"
                    label="昵称">
            </el-table-column>
            <el-table-column
                    prop="portrait"
                    label="头像照片"
                    width="80">
                <template slot-scope="scope">
                    <el-avatar shape="square" :size="22" :src="scope.row.portrait"></el-avatar>
                </template>
            </el-table-column>
            <el-table-column
                    prop="user_name"
                    label="姓名">
            </el-table-column>
            <el-table-column
                    prop="phone"
                    label="手机号"
                    width="200">
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                <template slot-scope="scope">
                    <el-button
                            v-if="is_auth('store.coach.issave')"
                            @click="join_store(scope.row.coach_uuid)"
                            type="primary"
                            size="mini">添加
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="height: 20px;"></div>
        <el-pagination
                @current-change="getlist"
                :page-size="this.env.pageSize"
                :pager-count="7"
                background
                layout="prev, pager, next, total"
                :current-page.sync="page"
                :total="count">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: "Coachselectlist",
    data() {
        return {
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [], // 添加页面数据
            search: {},         // 搜索内容

        }
    },
    // 创建
    created() {
        this.init()
    },
    methods: {
        init() {
            this.search = {
                user_name: '',
                phone: '',
            }
            this.is_search();
        },
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取可选择教练列表
        getlist() {
            let postdata = {
                api_name: "store.coach.getcoach",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
            this.editPage = true    // 显示页面
        },
        // 保存门店教练
        join_store(coach_uuid = '') {
            let postdata = {
                api_name: "store.coach.issave",
                token: this.Tool.get_l_cache('token'),
                store_uuid: this.$route.query.store_uuid,
                coach_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.$emit('getlist')
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },

    }
}
</script>

<style scoped>

</style>
