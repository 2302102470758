<template>
    <div>
        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <!--搜索-->
            <div class="page-content-search">
                <el-form ref="form" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                            <el-col :span="12">
                                <el-form-item label="时间">
                                    <el-select v-model="searchtype" style="width: 30%">
                                        <el-option label="月统计" value="month"></el-option>
                                        <el-option label="周统计" value="week"></el-option>
                                        <el-option label="自定义" value="custom"></el-option>
                                    </el-select>
                                    <el-date-picker
                                            style="width: 70%"
                                            v-show="searchtype == 'month'"
                                            v-model="month"
                                            type="month"
                                            placeholder="选择月"
                                            value-format="yyyy-MM"
                                    >
                                    </el-date-picker>
                                    <el-date-picker
                                            style="width: 70%"
                                            v-show="searchtype == 'week'"
                                            v-model="selectWeek"
                                            type="week"
                                            format="yyyy 第 WW 周"
                                            value-format="yyyy-MM-dd"
                                            :picker-options="pickerOptions"
                                            placeholder="选择周"
                                            @change="searchWeekTime">

                                    </el-date-picker>
                                    <el-date-picker
                                            style="width: 70%"
                                            v-show="searchtype == 'custom'"
                                            v-model="time"
                                            type="daterange"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            format="yyyy 年 MM 月 dd 日"
                                            value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>

                    </el-row>

                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="getinfo">搜索
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

            <div v-loading="loading">
                <!--收入-->
                <h3>总数据</h3>
                <el-row :gutter="16">
                    <el-col :span="6" v-if="is_auth('reportforms.store.total_money')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon fa fa-jpy default"></i>
                                <div>
                                    <div>{{ info.total_money }}</div>
                                    <el-tooltip class="item" effect="dark" content="已下课的所有收入（扣除优惠券）"
                                                placement="bottom-start">
                                        <div>总收入</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.group_total_money')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon fa fa-credit-card-alt default"></i>
                                <div>
                                    <div>￥{{ info.group_total_money }}</div>
                                    <el-tooltip class="item" effect="dark" content="团课收入"
                                                placement="bottom-start">
                                        <div>团课收入</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.camp_total_money')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon fa fa-weixin default"></i>
                                <div>
                                    <div>￥{{ info.camp_total_money }}</div>
                                    <el-tooltip class="item" effect="dark" content="训练营收入"
                                                placement="bottom-start">
                                        <div>训练营收入</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.pt_total_money')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon fa fa-ticket default"></i>
                                <div>
                                    <div>￥{{ info.pt_total_money }}</div>
                                    <el-tooltip class="item" effect="dark" content="私教收入"
                                                placement="bottom-start">
                                        <div>私教收入</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div style="height: 20px"></div>

                <!--团课-->
                <h3>团课</h3>
                <el-row :gutter="16">
                    <el-col :span="6" v-if="is_auth('reportforms.store.group_order_num')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon fa fa-jpy blue"></i>
                                <div>
                                    <div>￥{{ info.group_order_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="团课订单数"
                                                placement="bottom-start">
                                        <div>团课订单数</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.group_people_num')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon el-icon-s-order blue"></i>
                                <div>
                                    <div>{{ info.group_people_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="团课上课人数"
                                                placement="bottom-start">
                                        <div>团课上课人数</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.group_person_time_num')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon fa fa-users blue"></i>
                                <div>
                                    <div>{{ info.group_person_time_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="团课上课人次"
                                                placement="bottom-start">
                                        <div>团课上课人次</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.group_bookings_rate')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon fa fa-users blue"></i>
                                <div>
                                    <div>{{ info.group_bookings_rate }}</div>
                                    <el-tooltip class="item" effect="dark" content="团课上课人次/团课排课人数上限"
                                                placement="bottom-start">
                                        <div>团课预约率</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>

                </el-row>
                <div style="height: 20px"></div>
                <el-row :gutter="16">
                    <el-col :span="6" v-if="is_auth('reportforms.store.group_repurchase_rate')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon fa fa-users blue"></i>
                                <div>
                                    <div>{{ info.group_repurchase_rate }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内团课上课2次已上人数/所有团课上课人数"
                                                placement="bottom-start">
                                        <div>团课复购率</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.group_start_rate')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon fa fa-users blue"></i>
                                <div>
                                    <div>{{ info.group_start_rate }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内团课开课数/统计时段内团课排课数"
                                                placement="bottom-start">
                                        <div>团课开课率</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div style="height: 20px"></div>

                <!--训练营-->
                <h3>训练营</h3>
                <el-row :gutter="16">
                    <el-col :span="6" v-if="is_auth('reportforms.store.camp_order_num')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon el-icon-s-order yellow"></i>
                                <div>
                                    <div>{{ info.camp_order_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="训练营订单数"
                                                placement="bottom-start">
                                        <div>训练营订单数</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.camp_people_num')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon el-icon-s-order yellow"></i>
                                <div>
                                    <div>{{ info.camp_people_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="训练营上课人数"
                                                placement="bottom-start">
                                        <div>训练营上课人数</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.camp_person_time_num')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon el-icon-s-order yellow"></i>
                                <div>
                                    <div>{{ info.camp_person_time_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="训练营上课人次"
                                                placement="bottom-start">
                                        <div>训练营上课人次</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.camp_bookings_rate')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon el-icon-s-order yellow"></i>
                                <div>
                                    <div>{{ info.camp_bookings_rate }}</div>
                                    <el-tooltip class="item" effect="dark" content="训练营上课人次/训练营排课人数上限"
                                                placement="bottom-start">
                                        <div>训练营预约率</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div style="height: 20px"></div>
                <el-row :gutter="16">
                    <el-col :span="6" v-if="is_auth('reportforms.store.camp_repurchase_rate')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon el-icon-s-order yellow"></i>
                                <div>
                                    <div>{{ info.camp_repurchase_rate }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内训练营上课2次已上人数/所有训练营上课人数"
                                                placement="bottom-start">
                                        <div>训练营复购率</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.camp_start_rate')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon el-icon-s-order yellow"></i>
                                <div>
                                    <div>{{ info.camp_start_rate }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内训练营开课数/统计时段内训练营排课数"
                                                placement="bottom-start">
                                        <div>训练营开课率</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div style="height: 20px"></div>

                <!--私教-->
                <h3>私教</h3>
                <el-row :gutter="16">
                    <el-col :span="6" v-if="is_auth('reportforms.store.pt_people_num')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon fa fa-jpy red"></i>
                                <div>
                                    <div>{{ info.pt_people_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内在门店上课的人数"
                                                placement="bottom-start">
                                        <div>私教上课人数</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.store.pt_consume_num')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon el-icon-s-order red"></i>
                                <div>
                                    <div>{{ info.pt_consume_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内在门店消课的数量"
                                                placement="bottom-start">
                                        <div>私教消课数</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div style="height: 20px"></div>
            </div>


        </div>
    </div>
</template>
<script>


export default {

    data() {
        return {
            searchtype: 'month',
            page_name: '门店数据',
            month: '',           // 月份
            selectWeek: '',
            // 日期选择 配置
            pickerOptions: {
                firstDayOfWeek: 1,      // 从周一开始
            },
            weekArrangeData: [],        // 周数据

            time: null,
            info: {},

            settagPage: false,
            store_uuid:'',
            loading:false
        }
    },
    // 创建
    created() {
        this.getdatatime()

    },
    // 安装
    mounted() {

    },
    methods: {
        // 初始化
        init() {
            let store_uuid = this.$route.query.store_uuid
            if (store_uuid !== undefined) {
                this.store_uuid = store_uuid
                this.getinfo()  // 获取门店信息
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.$router.push({path: '/store/store'})    // 返回列表
                    }
                });
            }
        },
        // 显示图表，暂无用
        page_display() {
            // this.settagPage = true    // 显示页面
        },
        getdatatime() {
            let date = new Date();
            let y = date.getFullYear();
            let M = date.getMonth() + 1;
            let theDay = `${y.toString()}-${M.toString()}`;
            this.time = theDay;
        },
        // 选择周时间
        searchWeekTime() {
            console.log('选择周时间')
            let selectWeek = this.selectWeek        // 选择的周数据

            let DateArea = this.getDateArea(selectWeek)
            this.weekArrangeData = [DateArea[0]['date'], DateArea[6]['date']]
            // this.weekArrangeData.push(DateArea[0]['date'], DateArea[6]['date'])
        },
        // 获取选中的一周日期数据
        getDateArea(currentTime) {
            let currentDate = new Date(currentTime)
            let timesStamp = currentDate.getTime()
            let currenDay = currentDate.getDay()
            let dates = []
            for (let i = 0; i < 7; i++) {
                // let date = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString().replace(/\//g, '-')
                let date = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toISOString().split('T')[0]
                dates.push({
                    date,
                });
            }
            return dates
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取数据
        getinfo() {
            let date = [''];
            if (this.searchtype == 'month') {
                if (!this.Tool.is_empty(this.month)) {
                    date = [this.month + '-01']
                }
            } else if (this.searchtype == 'week') {
                date = this.weekArrangeData
            } else if (this.searchtype == 'custom') {
                date = this.time
            }

            let postdata = {
                api_name: 'reportforms.store.getinfo',
                token: this.Tool.get_l_cache('token'),
                searchtype: this.searchtype,
                store_uuid: this.store_uuid,
                date,
            }
            // 判断时间条件
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.data.info
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-flex {
    display: flex;
    align-items: center;
    justify-items: center;
}

.default {
    color: #409EFF;
}

.red {
    color: #fc142f;
}

.yellow {
    color: #F4BA28;
}

.blue {
    color: #637FFB;
}

.icon {
    font-size: 60px;
    margin-right: 20px;
}
</style>
