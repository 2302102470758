<template>
    <!--内容-->
    <div class="page-content-x">
        <!--标题-->
        <el-row>
            <el-col :span="12"><h3>门店：{{ storeinfo.store_name }}</h3></el-col>
            <el-col :span="12">
                <div class="top-operation-button">
                    <el-button v-if="is_auth('store.classroom.issave') && storeinfo.company_uuid==='lsj'"
                               icon="el-icon-plus"
                               size="medium" type="primary" @click="add_page">添加
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
                v-loading="loading"
                :data="tableData"
                border
                size="medium"
                style="width: 100%">
            <el-table-column
                    prop="classroom_name"
                    label="名称"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="capacity"
                    label="可容纳人数"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="area"
                    label="面积"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="state"
                    label="状态"
                    width="100">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.state===1" type="success">正常</el-tag>
                    <el-tag size="mini" v-if="scope.row.state===2" type="warning">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="create_time"
                    label="创建时间"
            >
            </el-table-column>
            <el-table-column
                    prop="update_time"
                    label="更新时间"
            >
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="150">
                <template slot-scope="scope">
                    <el-button
                            v-if="is_auth('store.classroom.issave') && storeinfo.company_uuid==='lsj'"
                            @click="edit_page(scope.row)"
                            size="mini">编辑
                    </el-button>
                    <el-button
                            v-if="is_auth('store.classroom.isenable') && scope.row.state===2 && storeinfo.company_uuid==='lsj'"
                            @click="operation_tip(scope.row.classroom_uuid,scope.row.classroom_name,'isenable')"
                            type="primary" size="mini">启用
                    </el-button>
                    <el-button
                            v-if="is_auth('store.classroom.isdisable') && scope.row.state===1 && storeinfo.company_uuid==='lsj'"
                            @click="operation_tip(scope.row.classroom_uuid,scope.row.classroom_name,'isdisable')"
                            type="warning" size="mini">禁用
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--编辑-->
        <el-dialog v-loading="loading"
                   v-if="is_auth('store.classroom.issave') && storeinfo.company_uuid==='lsj'"
                   title="编辑教室"
                   :visible.sync="editPage"
                   width="50%"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
        >
            <el-row>
                <el-form size="medium" ref="form" :label-width="this.env.label_width">
                    <el-col :span="24">
                        <el-form-item label="教室名称">
                            <el-input v-model="info.classroom_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="容纳人数">
                            <el-input v-model="info.capacity"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="面积">
                            <el-input v-model="info.area"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <el-row style="margin: 10px 0px;">
                <el-button size="medium" type="primary" @click="save">确 定</el-button>
                <el-button size="medium" @click="editPage = false">取 消</el-button>
            </el-row>

        </el-dialog>
        <div style="height: 20px;"></div>
        <!--分页-->
        <el-pagination
                @current-change="getlist"
                :page-size="this.env.pageSize"
                :pager-count="7"
                background
                layout="prev, pager, next, total"
                :current-page.sync="page"
                :total="count">
        </el-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '门店教室',
            loading: true,              // 加载状态
            count: 0,                   // 数据总条数
            tableData: [],              // 列表内容
            page: 1,                    // 当前页数
            search: {},                 // 搜索条件
            storeinfo: {},              // 门店信息
            info: {},                   // 编辑信息
            editPage: false,            // 编辑页面的展示
            store_uuid: '',             // 门店uuid
        }
    },
    // 创建
    created() {
        // this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let store_uuid = this.$route.query.store_uuid
            if (store_uuid !== undefined) {
                this.store_uuid = store_uuid
                this.getinfo()  // 获取门店信息
                this.getlist()
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.$router.push({path: '/store/store'})    // 返回列表
                    }
                });
            }
        },
        // 门店详情
        getinfo() {
            let postdata = {
                api_name: "store.store.getinfo",
                token: this.Tool.get_l_cache('token'),
                store_uuid: this.store_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.storeinfo = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "store.classroom.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
                store_uuid: this.store_uuid
            }
            // 非必须参数（搜索条件）- 暂时无用
            // Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(classroom_uuid = '', classroom_name = '', operation = '') {

            let tip = ''
            let options = {}
            // 启用
            if (operation === 'isenable') {
                tip = '启用【' + classroom_name + '】？'
            }
            // 禁用
            if (operation === 'isdisable') {
                tip = '禁用【' + classroom_name + '】？'
            }
            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(classroom_uuid, operation)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(classroom_uuid = '', operation = '') {
            let postdata = {
                api_name: "store.classroom." + operation,
                token: this.Tool.get_l_cache('token'),
                classroom_uuid: classroom_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 添加页面显示
        add_page() {
            this.info = {
                classroom_name: '',
                capacity: '',
                area: '',
            }
            this.editPage = true    // 显示页面
        },
        // 编辑显示
        edit_page(row = {}) {
            this.info = row
            this.editPage = true    // 显示页面
        },
        // 保存
        save() {
            let postdata = {
                api_name: 'store.classroom.issave',
                token: this.Tool.get_l_cache('token'),
                store_uuid: this.store_uuid
            }
            Object.assign(postdata, this.info);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.editPage = false
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tag_colour {
    height: 28px;
    /*background: #2c3e50;*/
    border: 1px solid;
    text-align: center;
    line-height: 28px;
}

.tag_colour-tag {
    border: 0px;
}
</style>
